@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Martian+Mono:wght@100..800&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
  background-color: #79ffe1;
}
